import React from "react";

import {
    FormControl,
    FormLabel,
    Input,
    FormHelperText,
} from "@chakra-ui/react";

interface PropsType {
    label?: string;
    type: string;
    helper?: string;
    value: string;
    onChangeText: React.ChangeEventHandler<HTMLInputElement> | undefined;
    isInvalid?: boolean;
    isRequired?: boolean;
    style?: object;
    placeholder?: string;
    disabled?: boolean
}

export default function InputStyleOne({
    label,
    type,
    helper,
    value,
    onChangeText,
    isInvalid,
    isRequired,
    style,
    placeholder,
    disabled
}: PropsType) {
    return (
        <FormControl isInvalid={isInvalid} isRequired={isRequired}>
            <FormLabel color="dark">{label}</FormLabel>
            <Input
                h={{ base: 30, sm: "" }}
                overflow={"hidden"}
                textOverflow="ellipsis"
                variant="flushed"
                size="sm"
                placeholder={placeholder}
                fontSize={""}
                style={{ ...style }}
                type={type}
                value={value}
                disabled={disabled}
                _disabled={{ 
                    opacity: 0.4,
                    cursor: "not-allowed",}}
                onChange={onChangeText}
            />
            <FormHelperText>{helper}</FormHelperText>
        </FormControl>
    );
}
