import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch} from "./index.store";
// import type { PayloadAction } from '@reduxjs/toolkit'
import { loginOperator } from "../services/operator";
import jwt_decode from "jwt-decode";
import { Operator } from "../types/operator";


const initialState  = {
  operator: {
    operatorInfo: {},
    operatorToken: "",
    error: false,
    messageError: ""
  }
}

const store = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginStore(state, action){
            state.operator = {...action.payload}
        },
        logoutOperator(state){
            state.operator = {
                operatorInfo: {},
                operatorToken: "",
                error: false,
                messageError: ""
              }
        }
    }

})

export const {logoutOperator} = store.actions;
export default store.reducer    

export function asyncLoginOperatorStore(body : {
    email: string,
    password: string,
    rootId: string,
    branchId: string
}){
    return async function(dispatch: AppDispatch){
        const response = await loginOperator(body)
        if(response?.status === 200){
            const decoded : Operator = jwt_decode(response.data);
            return dispatch(store.actions.loginStore({operatorInfo: decoded, operatorToken: response.data, error: false, messageError: ""}))
        }
        return dispatch(store.actions.loginStore({error: true, messageError: response.data,}))
    }
}