import { api } from './api';
import rootId from '../data/rootId';

export const getBranchesService = async (): Promise<any> => {
	console.log('getBranchesService [API]');
	return await api('/operatorbranchs/' + rootId)
		.then((res) => res)
		.catch((err) => err.response);
};

// Login sem rootId
export const loginOperator = async (body: { email: string; password: string; branchId: string; rootId: string }): Promise<any> => {
	console.log('loginOperator [API]');
	return await api
		.post('auth/operator/direct', body)
		.then((res) => res)
		.catch((err) => err.response);
};

export const createSimpleCustomer = async (body: any, token: string) => {
	console.log('createSimpleCustomer [API]');
	return await api
		.post('operatorcustomer', body, {
			headers: {
				'x-auth-token': token,
			},
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const sendsms = async (body: any, token: string) => {
	console.log('sendsms [API]');
	return await api
		.post('operatorcustomer/sendsms', body, {
			headers: {
				'x-auth-token': token,
			},
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const getCustomers = async (body: any, token: string) => {
	console.log('getCustomers [API]');
	return await api
		.patch('operatorcustomer', body, {
			headers: {
				'x-auth-token': token,
			},
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const updateCustomer = async (id: any, body: any, token: string) => {
	console.log('updateCustomer [API]');
	return await api
		.patch(`operatorcustomer/updateCustomer/${id}`, body, {
			headers: {
				'x-auth-token': token,
			},
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const sendEmailOperator = async (email: string, branchId: string) => {
	console.log('sendEmailOperator [API]');
	return await api
		.post('recoverpassword/operator/direct', { email, branchId, rootId })
		.then((res) => res)
		.catch((err) => err.response);
};

export const recoveryPasswordOperator = async (password: string, token: string) => {
	console.log('recoveryPasswordOperator [API]');
	return await api
		.post('recoverpassword/changeoperator/' + token, { password })
		.then((res) => res)
		.catch((err) => err.response);
};

export const transaction = async (body: any, token: string) => {
	console.log('transaction [API]');
	return await api
		.post('transactions', body, {
			headers: {
				'x-auth-token': token,
			},
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const reward = async (body: any, token: string) => {
	console.log('reward [API]');
	return await api
		.post('reward', body, {
			headers: {
				'x-auth-token': token,
			},
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const getRules = async (token: string) => {
	console.log('getRules [API]');

	return await api
		.get('operatorrules', {
			headers: {
				'x-auth-token': token,
			},
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const getExtractCashbackOperator = async (token: string, customerId: string) => {
	console.log('getExtractCashbackOperator [API]');
	return await api('customerbalance/operator/cashback/' + customerId, {
		headers: {
			'x-auth-token': token,
		},
	})
		.then((res) => res)
		.catch((e) => e.response);
};

export const getExtractLoyaltyOperator = async (token: string, customerId: string) => {
	console.log('getExtractLoyaltyOperator [API]');
	return await api('customerbalance/operator/loyalty/' + customerId, {
		headers: {
			'x-auth-token': token,
		},
	})
		.then((res) => res)
		.catch((e) => e.response);
};

export const getBalanceCashbackOperator = async (token: string, customerId: string) => {
	console.log('getBalanceCashbackOperator [API]');
	return await api('customertotalbalance/operator/cashback/' + customerId, {
		headers: {
			'x-auth-token': token,
		},
	})
		.then((res) => res)
		.catch((e) => e.response);
};

export const getBalanceLoyaltyOperator = async (token: string, customerId: string) => {
	console.log('getBalanceLoyaltyOperator [API]');
	return await api('customertotalbalance/operator/loyalty/' + customerId, {
		headers: {
			'x-auth-token': token,
		},
	})
		.then((res) => res)
		.catch((e) => e.response);
};

export const validateReward = async (token: string, voucher: string) => {
	console.log('validateReward [API]');
	return await api
		.post(
			'validateRewardVoucher/' + voucher,
			{},
			{
				headers: {
					'x-auth-token': token,
				},
			}
		)
		.then((res) => res)
		.catch((e) => e.response);
};
