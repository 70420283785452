import axios from 'axios';

const apiUrl = 'https://api.tooweze.com/api/';
// const apiUrl = 'http://localhost:3000/api/';

export const api = axios.create({
	baseURL: apiUrl,
	timeout: 10000,
	headers: { 'Content-Type': 'application/json' },
});

// teste
