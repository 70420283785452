import { useState } from 'react';

import {
	Box,
	Flex,
	Text,
	IconButton,
	Button,
	Stack,
	Collapse,
	Link,
	Popover,
	PopoverTrigger,
	PopoverContent,
	useDisclosure,
	chakra,
	Drawer,
	DrawerCloseButton,
	DrawerOverlay,
	DrawerContent,
	DrawerBody,
	HStack,
	Menu,
	MenuButton,
	Avatar,
	VStack,
	MenuList,
	MenuItem,
	Image,
} from '@chakra-ui/react';

import { FiMenu, FiUser, FiArrowDown, FiChevronDown } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/index.store';
import useGetConfig from '../../hooks/useGetConfig';

const heightHeader = 70;

const IconUser = chakra(FiUser);
const IconMenu = chakra(FiMenu);
const IconArrowDown = chakra(FiArrowDown);

export default function Header() {
	const { isOpen, onClose, onOpen } = useDisclosure();

	const [showAccountNav, setShowAccountNav] = useState<boolean>(false);
	const { customerState } = useSelector((state: RootState) => state.customer);

	const data = useGetConfig();

	const openNavMobile = () => {
		if (!isOpen) {
			setShowAccountNav(false);
			return onOpen();
		}

		if (showAccountNav === false) {
			return onClose();
		}
		return setShowAccountNav(false);
	};

	const openAccountNavMobile = async () => {
		if (!isOpen) {
			setShowAccountNav(true);
			return onOpen();
		}
		if (showAccountNav === true) {
			return onClose();
		}
		return setShowAccountNav(true);
	};

	const colors = data?.colors;

	return (
		<Box>
			<Flex
				pos='fixed'
				top='0'
				zIndex={3}
				bg={colors?.primary || 'blue_light'}
				color={'white'}
				h={heightHeader}
				w='100%'
				py={{ base: 2 }}
				px={{ base: 4 }}
				align={'center'}
			>
				<Flex flex={{ base: 1, md: 'auto' }} ml={{ base: -2 }} display={{ base: 'flex', lg: 'none' }}>
					<IconButton
						onClick={openNavMobile}
						color={colors?.secondary || 'white'}
						bg={colors?.primary || 'blue_light'}
						aria-label={'Toggle Navigation'}
						icon={<IconMenu fontSize={'2xl'} _hover={{ color: colors?.primary || 'blue_light' }} />}
					/>
				</Flex>
				<Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }} alignItems={'center'}>
					<Link href='/'>
						<Image src={data?.logo} width={120} />
					</Link>

					<Flex display={{ base: 'none', lg: 'flex' }} align='center' ml={10}>
						<DesktopNav />
					</Flex>
				</Flex>

				{!customerState.customerToken ? (
					<Stack flex={{ base: 1, md: 0 }} justify={'flex-end'} direction={'row'} spacing={6}>
						<Button
							display={{ base: 'none', lg: 'inline-flex' }}
							as={'a'}
							fontSize={'sm'}
							fontWeight={400}
							variant={'link'}
							href={'/login'}
							color={colors?.seconday || 'white'}
							_hover={{
								textDecoration: 'none',
							}}
						>
							Login
						</Button>

						<Link href='/register'>
							<Button
								display={{ base: 'none', lg: 'inline-flex' }}
								fontSize={'sm'}
								fontWeight={600}
								_hover={{ textDecoration: 'none' }}
								color={colors?.primary || 'blue_light'}
								bg={colors?.secondary || 'white'}
							>
								Cadastre-se
							</Button>
						</Link>
						<Flex display={{ base: 'flex', lg: 'none' }}>
							<IconButton
								onClick={openAccountNavMobile}
								color={colors?.secondary || 'white'}
								bg={colors?.primary || 'blue_light'}
								aria-label={'Toggle Navigation'}
								icon={<IconUser fontSize={'2xl'} _hover={{ color: colors?.primary || 'blue_light' }} />}
							/>
						</Flex>
					</Stack>
				) : (
					<HStack flex={{ base: 1, md: 0 }} justify={'flex-end'}>
						<Flex alignItems={'center'}>
							<Menu>
								<MenuButton py={2} transition='all 0.3s' _focus={{ boxShadow: 'none' }}>
									<HStack>
										<Avatar size={'sm'} src={customerState.customerInfo.customer?.profilePic} />
										<VStack display={{ base: 'none', md: 'flex' }} alignItems='flex-start' spacing='1px' ml='2'>
											<Text fontSize='sm'>{customerState.customerInfo.customer?.name.split(' ')[0]}</Text>
										</VStack>
										<Box display={{ base: 'none', md: 'flex' }}>
											<FiChevronDown />
										</Box>
									</HStack>
								</MenuButton>
								<MenuList paddingX={2} bg={'white'} borderColor={colors?.primary || 'blue_light'}>
									<Link href='/profile'>
										<MenuItem
											borderRadius={'base'}
											color={colors?.third || 'dark'}
											_hover={{
												bg: 'gray_light',
												color: colors?.third || 'dark',
											}}
										>
											Ver perfil
										</MenuItem>
									</Link>
									<Link href='/store'>
										<MenuItem
											color='dark'
											borderRadius={'base'}
											_hover={{
												bg: 'gray_light',
												color: colors?.third || 'dark',
											}}
										>
											Loja de prêmios
										</MenuItem>
									</Link>
								</MenuList>
							</Menu>
						</Flex>
					</HStack>
				)}
			</Flex>

			<Drawer isOpen={showAccountNav ? false : isOpen} placement='left' onClose={onClose}>
				<MobileNav onClose={onClose} />
			</Drawer>
			<Drawer isOpen={showAccountNav ? isOpen : false} placement='right' onClose={onClose}>
				<MobileNavAccount onClose={onClose} />
			</Drawer>
			<Box marginTop={isOpen ? '0' : '68px'}></Box>
		</Box>
	);
}

const DesktopNav = () => {
	const [data] = useState(() => {
		let config = null;
		const configStorage = localStorage.getItem('@config');
		if (configStorage) {
			config = JSON.parse(configStorage);
		}
		return config;
	});

	const colors = data?.colors;

	return (
		<Stack direction={'row'} spacing={4}>
			{NAV_ITEMS.map((navItem) => (
				<Box key={navItem.label}>
					<Popover trigger={'hover'} placement={'bottom-start'}>
						<PopoverTrigger>
							<Link
								p={2}
								href={navItem.href ?? '#'}
								fontSize={'small'}
								fontWeight={500}
								color={colors?.secondary || 'white'}
								_hover={{
									textDecoration: 'none',
									color: colors?.third || 'dark',
								}}
							>
								{navItem.label}
							</Link>
						</PopoverTrigger>

						{navItem.children && (
							<PopoverContent border={0} boxShadow={'xl'} bg={colors?.secondary || 'white'} p={4} rounded={'xl'} minW={'sm'}>
								<Stack>
									{navItem.children.map((child) => (
										<DesktopSubNav key={child.label} {...child} />
									))}
								</Stack>
							</PopoverContent>
						)}
					</Popover>
				</Box>
			))}
		</Stack>
	);
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
	const [data] = useState(() => {
		let config = null;
		const configStorage = localStorage.getItem('@config');
		if (configStorage) {
			config = JSON.parse(configStorage);
		}
		return config;
	});

	const colors = data?.colors;

	return (
		<Link href={href} role={'group'} display={'block'} p={2} rounded={'md'}>
			<Stack direction={'row'} align={'center'}>
				<Box>
					<Text transition={'all .3s ease'} _groupHover={{ color: colors?.primary || 'blue' }} fontWeight={500}>
						{label}
					</Text>
					<Text fontSize={'sm'}>{subLabel}</Text>
				</Box>
				<Flex
					transition={'all .3s ease'}
					transform={'translateX(-10px)'}
					opacity={0}
					_groupHover={{
						opacity: '100%',
						transform: 'translateX(0)',
					}}
					justify={'flex-end'}
					align={'center'}
					flex={1}
				></Flex>
			</Stack>
		</Link>
	);
};

const MobileNav = ({ onClose }: { onClose: () => void }) => {
	return (
		<>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton onClick={() => onClose()} />
				<DrawerBody p={0}>
					<DrawerCloseButton />
					<Box marginTop={14} w='100%'>
						{NAV_ITEMS.map((navItem) => (
							<MobileNavItem key={navItem.label} {...navItem} />
						))}
					</Box>
				</DrawerBody>
			</DrawerContent>
		</>
	);
};

const MobileNavAccount = ({ onClose }: { onClose: () => void }) => {
	return (
		<>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton onClick={() => onClose()} />
				<DrawerBody p={0}>
					<DrawerCloseButton />
					<Box marginTop={14} w='100%'>
						{NAV_ITEM_ACCOUNT.map((navItem) => (
							<MobileNavItemAccount key={navItem.label} {...navItem} />
						))}
					</Box>
				</DrawerBody>
			</DrawerContent>
		</>
	);
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
	const { isOpen, onToggle } = useDisclosure();

	const [data] = useState(() => {
		let config = null;
		const configStorage = localStorage.getItem('@config');
		if (configStorage) {
			config = JSON.parse(configStorage);
		}
		return config;
	});

	const colors = data?.colors;

	return (
		<Stack marginY={4} paddingX={4} spacing={4} onClick={children && onToggle}>
			<Flex
				py={2}
				as={Link}
				href={href ?? '#'}
				justify={'space-between'}
				align={'center'}
				_hover={{
					textDecoration: 'none',
				}}
			>
				<Text
					fontWeight={600}
					_hover={{
						color: colors?.primary || 'blue_light',
					}}
					color={colors?.third || 'dark'}
				>
					{label}
				</Text>
				{children && <IconArrowDown transition={'all .25s ease-in-out'} transform={isOpen ? 'rotate(180deg)' : ''} w={6} h={6} />}
			</Flex>

			<Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
				<Stack mt={2} pl={4} borderLeft={1} borderStyle={'solid'} borderColor={colors?.third || 'gray'} align={'start'}>
					{children &&
						children.map((child) => (
							<Link key={child.label} py={2} href={child.href}>
								{child.label}
							</Link>
						))}
				</Stack>
			</Collapse>
		</Stack>
	);
};

const MobileNavItemAccount = ({ label, href }: NavItemAccount) => {
	const [data] = useState(() => {
		let config = null;
		const configStorage = localStorage.getItem('@config');
		if (configStorage) {
			config = JSON.parse(configStorage);
		}
		return config;
	});

	const colors = data?.colors;

	return (
		<Stack marginY={4} paddingX={4} spacing={4}>
			<Flex
				py={2}
				as={Link}
				href={href ?? '#'}
				justify={'space-between'}
				align={'center'}
				_hover={{
					textDecoration: 'none',
				}}
			>
				<Text
					fontWeight={600}
					_hover={{
						color: colors?.primary || 'blue_light',
					}}
					color={colors?.third || 'dark'}
				>
					{label}
				</Text>
			</Flex>
		</Stack>
	);
};

interface NavItem {
	label: string;
	subLabel?: string;
	children?: Array<NavItem>;
	href?: string;
}

interface NavItemAccount {
	label: string;
	href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
	{
		label: 'Home',
		href: '/',
	},
	{
		label: 'Sobre',
		href: '/about',
		// children: [
		//     {
		//         label: "ExemploLabel",
		//         subLabel: "Exemplo SubLabel",
		//     },
		// ],
	},
	{
		label: 'Regulamento',
		href: '/regulation',
	},
	{
		label: 'FAQ',
		href: '/faq',
	},
	{
		label: 'Parceiros',
		href: '/partners',
	},
	{
		label: 'Contato',
		href: '/contact',
	},
	{
		label: 'Loja',
		href: '/store',
	},
	{
		label: 'Cupons',
		href: '/coupon',
	},
];

const NAV_ITEM_ACCOUNT: Array<NavItemAccount> = [
	{
		label: 'Login',
		href: '/login',
	},
	{
		label: 'Cadastre-se',
		href: '/register',
	},
];
