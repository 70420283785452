import { api } from './api';
import rootId from '../data/rootId';

export const getConfigPortal = async () => {
	return await api('configurations/portal/' + rootId)
		.then((res) => res)
		.catch((response) => response.response);
};

export const getStore = async () => {
	return await api('store/' + rootId)
		.then((res) => res)
		.catch((response) => response.response);
};

export const getCarousels = async () => {
	return await api('carousel/' + rootId)
		.then((res) => res)
		.catch((response) => response.response);
};

export const getCupons = async () => {
	return await api('coupon/portal/' + rootId)
		.then((res) => res)
		.catch((response) => response.response);
};

export const getPartners = async () => {
	return await api('branch/partners/' + rootId)
		.then((res) => res)
		.catch((response) => response.response);
};
