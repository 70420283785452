import React, { useRef, useState, useEffect } from 'react';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Container,
	Divider,
	Flex,
	Heading,
	Input,
	Select,
	Stack,
	useToast,
	useDisclosure,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogBody,
	FormControl,
	Text,
} from '@chakra-ui/react';
import useGetConfig from '../../../../hooks/useGetConfig';
import { FiSearch } from 'react-icons/fi';
import TableCustomer from './tableCustomers';
import { useNavigate } from 'react-router-dom';
import { getCustomers, createSimpleCustomer, sendsms } from '../../../../services/operator';
import { CustomerType } from '../../../../types/customer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/index.store';

const options = [
	{ id: 1, label: 'Nome', flag: 'name' },
	{ id: 2, label: 'E-mail Pessoal', flag: 'email' },
	{ id: 3, label: 'Celular', flag: 'mobil' },
	{ id: 4, label: 'CPF', flag: 'personalId' },
];

export default function SearchCustomer() {
	const toast = useToast();
	const config = useGetConfig();
	const { colors } = config;
	const navigate = useNavigate();

	const [showTable, setShowTable] = useState<boolean>(false);
	const [showCadastro, setShowCadastro] = useState<boolean>(false);
	const [idSelected, setIdSelected] = useState<string>('');
	const [searchCustomer, setSearchCustomer] = useState<any>('');
	const [isLoading, setIsLoading] = useState(false);
	const [optionSelected, setOptionSelected] = useState(3);
	const customers = useRef<CustomerType[]>([]);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = useRef();

	const { operatorToken } = useSelector((state: RootState) => state.operator.operator);

	const validateFields = () => {
		if (!optionSelected) {
			toast({
				title: 'Oops!',
				description: 'Selecione um campo de busca!',
				duration: 2000,
				status: 'error',
			});
			return false;
		}
		if (!searchCustomer.trim()) {
			toast({
				title: 'Oops!',
				description: 'Preencha o dado do cliente!',
				duration: 2000,
				status: 'error',
			});
			return false;
		}
		return true;
	};

	const search = async () => {
		if (validateFields()) {
			// const flag = options.find((op) => op.id === optionSelected)?.flag;
			const flag = 'mobil';
			if (flag) {
				setIsLoading(true);

				const response = await getCustomers(
					{
						[flag]: searchCustomer.trim().replace(/\D/g, ''),
					},
					operatorToken
				);
				setIsLoading(false);

				if (response?.status === 200) {
					console.log('response.data', response.data);
					customers.current = response.data;
					console.log('customers.current.length', customers.current.length);

					if (customers.current.length > 0) {
						setShowTable(true);
					} else {
						setShowCadastro(true);
					}
				} else {
					toast({
						title: 'Oops!',
						description: 'Não foi possível buscar o cliente!',
						duration: 2000,
						status: 'error',
					});
				}
			}
		}
	};

	const navigateMenu = () => {
		if (!idSelected) {
			return toast({
				title: 'Oops!',
				description: 'Selecione um cliente!',
				duration: 2000,
				status: 'error',
			});
		}
		const customer = customers.current.find((customer) => customer._id === idSelected);
		return navigate('/operator/menu', { state: { customer } });
	};

	const eventHandler = (event: any) => {
		if (event.key === 'Enter') {
			search();
		}
	};

	// const handlePhone = (event: any) => {
	// 	let input = event.target;
	// 	input.value = phoneMask(input.value);
	// };

	const handlePhone = (value: any) => {
		console.log('value', value);
		if (!value) return '';
		// if (value.length < 16) {
		value = value.replace(/\D/g, '');
		value = value.replace(/(\d{2})(\d)/, '($1) $2');
		value = value.replace(/(\d)(\d{4})$/, '$1-$2');
		console.log('value 2', value);
		return value;
		// }
	};

	return (
		<Container maxW={'1100px'}>
			<Card px={4} mt={6} align='center'>
				<CardHeader>
					<Heading color={colors?.third} size='md'>
						Buscar cliente
					</Heading>
				</CardHeader>
				<Divider />
				<CardBody w='100%'>
					<Stack direction={{ base: 'column', md: 'row' }} w={'100%'} justify='center' spacing='4'>
						<Select
							w={{ sm: '100%', md: '25%' }}
							bg={colors?.secondary || 'white'}
							color={colors?.third || 'dark'}
							placeholder='Selecione uma opção'
							value={optionSelected}
							onChange={(e: any) => setOptionSelected(+e.target.value)}
							disabled={true}
						>
							{options.map((op, idx) => {
								return (
									<option key={idx} value={op.id}>
										{op.label}
									</option>
								);
							})}
						</Select>
						<Input
							w={{ base: '100%', md: '60%' }}
							bg={colors?.secondary || 'white'}
							type='text'
							maxLength={15}
							onKeyDown={eventHandler}
							placeholder='Procurar...'
							value={searchCustomer}
							onChange={(e: any) => {
								setSearchCustomer(handlePhone(e.target.value));
							}}
						/>
						<Button
							onClick={search}
							isLoading={isLoading}
							w={{ base: '100%', md: '12%' }}
							leftIcon={<FiSearch color={colors?.secondary} />}
							variant='solid'
							color={colors?.secondary}
							bg={colors?.primary}
						>
							Buscar
						</Button>
					</Stack>
					{showTable && (
						<>
							<TableCustomer customers={customers.current} id={idSelected} selectId={(id: string) => setIdSelected(id)} />
							{Array.isArray(customers.current) && customers.current.length ? (
								<Flex w='100%' mt={6} alignItems={'flex-end'} justifyContent={'flex-end'}>
									<Button onClick={navigateMenu} variant={'solid'} bg={colors?.primary} color={colors?.secondary}>
										Confirmar seleção
									</Button>
								</Flex>
							) : null}
						</>
					)}

					{showCadastro === true ? (
						<>
							<Flex w={'full'} justify='center' align='center' padding={5}>
								<Flex>
									<Stack spacing={4} flex='1' marginRight={2}>
										<Text cursor='pointer'>Cliente Não Encontrado, Deseja cadastrá-lo?</Text>
									</Stack>
									<Stack spacing={2} flex='1'>
										<Button onClick={onOpen} isLoading={isLoading} size='sm' flex='1' bg={colors.primary} color={colors.secondary} _hover={{}}>
											Cadastrar
										</Button>
									</Stack>
								</Flex>
							</Flex>
						</>
					) : null}

					<CadastroForm
						cancelRef={cancelRef}
						isOpen={isOpen}
						onClose={onClose}
						searchCustomer={searchCustomer}
						setSearchCustomer={setSearchCustomer}
						operatorToken={operatorToken}
						search={search}
						setShowCadastro={setShowCadastro}
					/>
				</CardBody>
			</Card>
		</Container>
	);
}
interface ICadastroForm {
	isOpen: boolean;
	onClose: () => void;
	cancelRef: React.RefObject<any>;
	searchCustomer: any;
	operatorToken: string;
	setSearchCustomer: any;
	search: any;
	setShowCadastro: any;
}

function CadastroForm({
	isOpen,
	onClose,
	cancelRef,
	searchCustomer,
	operatorToken,
	setSearchCustomer,
	search,
	setShowCadastro,
}: ICadastroForm): JSX.Element {
	const { colors } = useGetConfig();
	const toast = useToast();
	const [data, setData] = useState({
		email: '',
		name: '',
		mobil: '',
		smsCode: '',
	});
	const [dataInvalid, setDataInvalid] = useState({
		emailInvalid: false,
		mobilInvalid: false,
		nameInvalid: false,
		smscodeInvalid: false,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingSMS, setIsLoadingSMS] = useState(false);
	const [contador, setContador] = useState(10);

	useEffect(() => {
		setData((prev) => ({ ...prev, mobil: searchCustomer }));
		if (isOpen) {
			resendSMS();
		}
	}, [searchCustomer, isOpen]);

	const validateFields = () => {
		if (!data?.name.trim()) {
			setDataInvalid((prev) => {
				return { ...prev, nameInvalid: true };
			});
			toast({
				title: 'Oops',
				description: 'Nome é obrigatório.',
				status: 'error',
				isClosable: true,
			});
			return false;
		}
		if (!data?.mobil.trim()) {
			setDataInvalid((prev) => {
				return { ...prev, mobilInvalid: true };
			});
			toast({
				title: 'Oops',
				description: 'Celular é obrigatório.',
				status: 'error',
				isClosable: true,
			});
			return false;
		}
		if (!data?.smsCode.trim()) {
			setDataInvalid((prev) => {
				return { ...prev, smscodeInvalid: true };
			});
			toast({
				title: 'Oops',
				description: 'Digite o código SMS enviado para o celular do cliente.',
				status: 'error',
				isClosable: true,
			});
			return false;
		}

		return true;
	};

	const createCustomer = async () => {
		try {
			if (validateFields()) {
				setDataInvalid({ nameInvalid: false, emailInvalid: false, mobilInvalid: false, smscodeInvalid: false });
				setIsLoading(true);
				const response = await createSimpleCustomer(data, operatorToken);
				setIsLoading(false);
				if (response?.status === 200) {
					toast({
						title: 'Eeba',
						description: 'Cadastro criado com sucesso!',
						status: 'success',
						isClosable: true,
					});
					onClose();
					setSearchCustomer(data?.mobil);
					setShowCadastro(false);
					search();
				} else {
					let message;

					if (typeof response.data === 'string') {
						if (response.data === 'personalId, personalSecondId, Mobile, ExternalId or Email already registered!') {
							message = 'Cliente Já Cadastrado!';
						}

						if (response.data === 'Invalid Code!') {
							message = 'Código SMS Incorreto!';
						}

						toast({
							title: 'Oops',
							description: message ? message : response?.data.toString(),
							status: 'error',
							isClosable: true,
						});
					} else {
						toast({
							title: 'Oops',
							description: 'Erro desconhecido.',
							status: 'error',
							isClosable: true,
						});
					}
				}
			}
		} catch (error: any) {
			console.log('error cadastro', error);
			toast({
				title: 'Oops',
				description: error,
				status: 'error',
				isClosable: true,
			});
		}
	};

	const resendSMS = async () => {
		console.log('resendSMS');
		if (dataInvalid.mobilInvalid === false) {
			setIsLoadingSMS(true);
			const response = await sendsms({ mobil: data?.mobil }, operatorToken);

			if (response.status === 200) {
				toast({
					title: 'Eeba',
					description: 'Código SMS enviado para o Celular do Cliente.',
					status: 'success',
					isClosable: true,
				});
			} else if (response.status === 400 && response.data === 'Too many attempt for the day.') {
				toast({
					title: 'Oops',
					description: 'Muitas tentativas para este celular hoje.',
					status: 'error',
					isClosable: true,
				});
			} else {
				toast({
					title: 'Oops',
					description: 'Não foi possível enviar o SMS.',
					status: 'error',
					isClosable: true,
				});
			}
			setTimeout(() => {}, 5000);
			// onClose();
		}
	};

	useEffect(() => {
		let interval: any;

		if (isLoadingSMS) {
			interval = setInterval(() => {
				setContador((prevContador) => {
					if (prevContador === 0) {
						setIsLoadingSMS(false);
						clearInterval(interval);
						return 10;
					}
					return prevContador - 1;
				});
			}, 1000);
		}
		return () => clearInterval(interval);
	}, [isLoadingSMS]);

	return (
		<AlertDialog motionPreset='slideInBottom' leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
			<AlertDialogOverlay />

			<AlertDialogContent>
				<AlertDialogCloseButton />
				<AlertDialogBody>
					<Stack spacing={4} w={'full'} maxW={'md'} rounded={'xl'} p={6} my={6}>
						<Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
							Cadastrar Cliente?
						</Heading>
						<Text fontSize={{ base: 'sm', sm: 'md' }}>Foi enviado um código para o celular do cliente, confirme para cadastrar.</Text>
						<FormControl id='name'>
							<Input
								isInvalid={dataInvalid.nameInvalid}
								placeholder='Nome'
								_placeholder={{ color: 'gray.500' }}
								type='text'
								value={data?.name}
								onChange={(e) =>
									setData((prev) => {
										return { ...prev, name: e.target.value };
									})
								}
							/>
						</FormControl>
						<FormControl id='mobil'>
							<Input
								isInvalid={dataInvalid.mobilInvalid}
								placeholder='Celular'
								_placeholder={{ color: 'gray.500' }}
								type='text'
								value={data?.mobil}
								onChange={(e) =>
									setData((prev) => {
										return { ...prev, mobil: e.target.value };
									})
								}
							/>
						</FormControl>

						<FormControl id='email'>
							<Input
								isInvalid={dataInvalid.emailInvalid}
								placeholder='Email Opcional'
								_placeholder={{ color: 'gray.500' }}
								type='email'
								value={data?.email}
								onChange={(e) =>
									setData((prev) => {
										return { ...prev, email: e.target.value };
									})
								}
							/>
						</FormControl>
						<FormControl id='smsCode'>
							<Flex>
								<Stack spacing={3} flex='1' marginRight={2}>
									<Input
										isInvalid={dataInvalid.smscodeInvalid}
										placeholder='Código'
										_placeholder={{ color: 'gray.500' }}
										type='number'
										value={data?.smsCode}
										onChange={(e) =>
											setData((prev) => {
												return { ...prev, smsCode: e.target.value };
											})
										}
									/>
								</Stack>
								<Stack spacing={3} flex='1'>
									<Button onClick={resendSMS} isLoading={isLoadingSMS} bg={colors.primary} color={colors.secondary} _hover={{}}>
										Reenviar
									</Button>
								</Stack>
							</Flex>
						</FormControl>
						<Stack spacing={6}>
							<Button onClick={createCustomer} isLoading={isLoading} bg={colors.primary} color={colors.secondary} _hover={{}}>
								Cadastrar
							</Button>
						</Stack>
						{isLoadingSMS && (
							<Text
								opacity={0.5}
								fontSize={{ base: 'sm', sm: 'md' }}
								style={{
									visibility: isLoadingSMS ? 'visible' : 'hidden',
									opacity: 0.5,
									position: 'absolute',
									bottom: 10,
								}}
							>
								Se não recebeu SMS aguarde {contador} segundos para reenviar.
							</Text>
						)}
					</Stack>
				</AlertDialogBody>
			</AlertDialogContent>
		</AlertDialog>
	);
}
