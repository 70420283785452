import jwtDecode from 'jwt-decode';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import LoadingPage from '../pages/loading';
import { getConfigPortal } from '../services/config';
import { setToken } from '../store/customer.store';
import { RootState, useAppDispatch } from '../store/index.store';
import { IConfigPortal } from '../types/configPortal';
import { expireOneDay, generateDateAndHour } from '../utils/date';
import standardConfigPortal from '../data/standardPortalConfig.json';

export default function Middlerware({ children, isProtected = false }: { children: JSX.Element; isProtected?: boolean }) {
	const location = useLocation();
	const [loading, setLoading] = useState(true);
	const dispatch = useAppDispatch();
	const { customerState } = useSelector((state: RootState) => state.customer);

	const auth = useSelector((store: RootState) => store.customer.customerState.customerToken);
	const operatorAuth = useSelector((store: RootState) => store.operator.operator.operatorToken);

	const isAuthenticated = () => {
		if (location.pathname.includes('operator')) {
			return operatorAuth;
		}
		return auth;
	};

	React.useEffect(() => {
		let token = localStorage.getItem('@token');
		if (token && !customerState.customerToken) {
			token = JSON.parse(token);
			const decoded = jwtDecode(token ?? '');
			dispatch(setToken({ token, decoded }));
		}
	}, [customerState, dispatch]);

	React.useEffect(() => {
		(async () => {
			await loader();
		})();
	}, [location]);

	React.useEffect(() => {
		if (!loading) {
			let link: any = document.querySelector("link[rel~='icon']");
			if (!link) {
				link = document.createElement('link');
				link.rel = 'icon';
				document.getElementsByTagName('head')[0].appendChild(link);
			}
			const config = JSON.parse(localStorage.getItem('@config') || '');
			link.href = config.favIco;
		}
	}, [loading]);

	const loader = async () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		const config = localStorage.getItem('@config');
		if (config) {
			const configJson = JSON.parse(config);
			const date = configJson.date;
			if (date) {
				if (expireOneDay(date)) {
					await getAndSetAndReturnConfig();
				}
			}
			setLoading(false);
		} else {
			await getAndSetAndReturnConfig();
			setLoading(false);
		}
	};

	const getAndSetAndReturnConfig = async () => {
		try {
			const response = await getConfigPortal();
			console.log('response', response);
			if (response.status === 200 && response.data && response.data !== '') {
				const data: IConfigPortal = response.data;
				data.date = generateDateAndHour();
				localStorage.setItem('@config', JSON.stringify(response.data));
				return data;
			} else {
				const data: any = standardConfigPortal;
				data.date = generateDateAndHour();
				localStorage.setItem('@config', JSON.stringify(standardConfigPortal));
				return data;
			}
		} catch (error) {
			return false;
		}
	};

	return loading ? (
		<LoadingPage />
	) : isProtected ? (
		isAuthenticated() ? (
			children
		) : (
			<Navigate to={location.pathname.includes('operator') ? '/operator/login' : '/login'} replace />
		)
	) : (
		children
	);
}
