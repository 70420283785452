import { Button, Flex, FormControl, FormLabel, Heading, Input, Stack, Text, Tooltip, useToast, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useGetConfig from '../../../hooks/useGetConfig';
import { recoveryPasswordOperator } from '../../../services/operator';

export default function ResetPasswordOperator(): JSX.Element {
	const {
		colors: { primary, secondary, third },
	} = useGetConfig();

	const { search } = useLocation();
	const toast = useToast();
	const navigate = useNavigate();

	const [data, setData] = useState({
		password: '',
		confirmPassword: '',
	});

	const [isLoading, setIsLoading] = useState(false);

	const isCheckedToUpdate = () => {
		return !data?.password || !data?.confirmPassword || data?.password !== data?.confirmPassword;
	};

	const recovery = async () => {
		const token = search.replace('?', '').replace('hash=', '');
		setIsLoading(true);
		const response = await recoveryPasswordOperator(data?.password, token);
		if (response.status !== 200) {
			setIsLoading(false);
			return toast({
				title: 'Oops!',
				description: 'Não foi possível redefinir a senha',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		}
		setIsLoading(false);
		toast({
			title: 'Eeba!',
			description: 'Senha redefinida com sucesso!',
			status: 'success',
			duration: 5000,
			isClosable: true,
		});
		setTimeout(() => {
			navigate('/operator/login');
		}, 1500);
	};

	return (
		<Flex minH={'100vh'} align={'center'} justify={'center'} bg={'gray.50'}>
			<VStack spacing={4}>
				<Stack align={'center'}>
					<Heading fontSize={'4xl'}>Bem vindo(a)</Heading>
					<Text fontSize={'lg'} textAlign='center' color={'gray.600'}>
						preencha os campos para redefinir sua senha
					</Text>
				</Stack>
				<Stack spacing={10} w={'full'} maxW={'md'} bg={primary} rounded={'xl'} boxShadow={'lg'} p={6} my={12}>
					<VStack spacing={4}>
						<FormControl id='password' isRequired>
							<FormLabel color={secondary}>Senha</FormLabel>
							<Input
								color={third}
								backgroundColor={secondary}
								type='password'
								onChange={(e) => setData({ ...data, password: e.target.value })}
								value={data?.password}
							/>
						</FormControl>
						<FormControl id='confirmPassword' isRequired>
							<FormLabel color={secondary}>Confirmar senha</FormLabel>
							<Input
								color={third}
								backgroundColor={secondary}
								type='password'
								value={data?.confirmPassword}
								onChange={(e) => setData({ ...data, confirmPassword: e.target.value })}
							/>
						</FormControl>
					</VStack>
					<Stack>
						<Tooltip label={!isCheckedToUpdate() ? '' : 'Os campos precisam estar preenchidos e as senhas devem ser iguais'}>
							<Button
								letterSpacing={1}
								bg={secondary}
								color={primary}
								_hover={{}}
								isLoading={isLoading}
								onClick={recovery}
								isDisabled={isCheckedToUpdate()}
							>
								REDEFINIR
							</Button>
						</Tooltip>
					</Stack>
				</Stack>
			</VStack>
		</Flex>
	);
}
