import React, { useState } from 'react';
import { Badge, Button, Card, CardBody, CardFooter, HStack, Heading, Image, Input, Select, Stack, Text, VStack, useToast } from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import useGetConfig from '../../../../../hooks/useGetConfig';
import { CustomerType } from '../../../../../types/customer';
import { VoucherType } from '../../../../../types/voucher';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/index.store';
import { validateReward } from '../../../../../services/operator';

const options = [{ id: 1, label: 'Voucher de Prêmio' }];

const ExchangePrize = ({ customer }: { customer: CustomerType }) => {
	const config = useGetConfig();
	const colors = config?.colors;

	const toast = useToast();
	const [voucherCode, setVoucherCode] = useState<string>('');
	const { operatorToken } = useSelector((root: RootState) => root.operator.operator);

	const [voucher, setVoucher] = useState<VoucherType | null>(null);

	const [isLoading, setIsLoading] = useState(false);

	const handleValidation = async () => {
		if (!voucherCode)
			return toast({
				title: 'Oops',
				description: 'Digite o código do voucher',
				isClosable: true,
				status: 'warning',
				duration: 2000,
			});

		setIsLoading(true);

		const response = await validateReward(operatorToken, voucherCode);
		setIsLoading(false);

		if (response.status === 404) {
			return toast({
				title: 'Oops',
				description: 'Voucher inválido',
				isClosable: true,
				status: 'error',
				duration: 2000,
			});
		}
		if (response.status !== 200) {
			return toast({
				title: 'Oops',
				description: 'Não foi possível checar o voucher',
				isClosable: true,
				status: 'error',
				duration: 2000,
			});
		}

		setVoucher(response.data.reason);
		toast({
			title: 'Eeba!',
			description: 'Voucher válido! Pode entregar o prêmio!',
			status: 'success',
			isClosable: true,
		});
	};

	const clean = () => {
		setVoucher(null);
		setVoucherCode('');
	};

	return (
		<VStack spacing={6} w='100%'>
			<Stack direction={{ base: 'column', md: 'row' }} w={'100%'} justify='center' spacing='4'>
				<Select w={{ sm: '100%', md: '25%' }} bg={colors?.secondary || 'white'} color={colors?.third || 'dark'}>
					{options.map((op, idx) => {
						return (
							<option key={idx} value={op.id}>
								{op.label}
							</option>
						);
					})}
				</Select>
				<Input
					w={{ base: '100%', md: '60%' }}
					bg={colors?.secondary || 'white'}
					type='text'
					placeholder='Procurar...'
					onChange={(e) => setVoucherCode(e.target.value)}
					value={voucherCode}
				/>
				<Button
					onClick={handleValidation}
					w={{ base: '100%', md: '12%' }}
					leftIcon={<FiSearch color={colors?.secondary} />}
					variant='solid'
					color={colors?.secondary}
					bg={colors?.primary}
					isLoading={isLoading}
				>
					Buscar
				</Button>
			</Stack>
			{voucher && (
				<VStack spacing={6}>
					<CardPrize colors={colors} voucher={voucher} />
					<HStack justifyContent={'flex-end'} alignItems={'flex-end'} w={'full'}>
						<Button onClick={clean} variant='outline' isLoading={isLoading}>
							Limpar
						</Button>
					</HStack>
				</VStack>
			)}
		</VStack>
	);
};

export const CardPrize = ({ colors, voucher, description }: { colors: any; voucher: VoucherType; description?: string }) => {
	return (
		<Card direction={{ base: 'column', sm: 'row' }} overflow='hidden' variant='outline' maxW={660} p={2}>
			<Image objectFit='contain' maxW={{ base: '100%', sm: '200px' }} src={voucher?.images?.[0]?.src} alt={voucher?.images?.[0]?.alt} />

			<Stack>
				<CardBody>
					<Heading size='md' pb={4}>
						{voucher.title}
					</Heading>
					<Text textAlign={'left'} fontSize={'sm'}>
						{voucher.description.substring(0, 100)}...
					</Text>
					{description && (
						<Text fontSize={'sm'} textAlign={'left'} py='2'>
							{description}
						</Text>
					)}
				</CardBody>

				<CardFooter display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
					<Badge variant='solid' bg={colors.primary}>
						{voucher.points} PONTOS
					</Badge>
					<Text fontSize='0.95em' fontWeight='bold'>
						Código:
						<Badge ml='1' fontSize='1.24em' colorScheme='orange'>
							{voucher.validationCode}
						</Badge>
					</Text>
				</CardFooter>
			</Stack>
		</Card>
	);
};

export default ExchangePrize;

// import React, { useState } from "react";
// import {
//   Button,
//   Container,
//   HStack,
//   Input,
//   Select,
//   Text,
//   VStack,
//   useToast,
// } from "@chakra-ui/react";
// import { useLocation } from "react-router-dom";
// import useGetConfig from "../../../../../hooks/useGetConfig";
// import { useSelector } from "react-redux";
// import { RootState } from "../../../../../store/index.store";
// import { validateReward } from "../../../../../services/operator";

// const options = [{ id: 1, label: "Voucher de Prêmio" }];

// export default function ValidateVoucher() {
//   const config = useGetConfig();
//   const toast = useToast();
//   const { colors } = config;
//   const { state } = useLocation();
//   const [voucherCode, setVoucherCode] = useState<string>("");
//   const { operatorToken } = useSelector(
//     (root: RootState) => root.operator.operator
//   );

//   const [isLoading, setIsLoading] = useState(false);

//   const handleValidation = async () => {
//     if (!voucherCode)
//       return toast({
//         title: "Oops",
//         description: "Digite o código do voucher",
//         isClosable: true,
//         status: "warning",
//         duration: 2000,
//       });

//     setIsLoading(true);

//     const response = await validateReward(operatorToken, voucherCode);
//     setIsLoading(false);

//     if (response.status === 404) {
//       return toast({
//         title: "Oops",
//         description: "Voucher inválido",
//         isClosable: true,
//         status: "error",
//         duration: 2000,
//       });
//     }
//   };

//   return (
//     <Container maxW={"1200px"}>
//       <Text mt={4} mb={4} textAlign={"center"}>
//         {state?.customer?.name}
//       </Text>
//       <HStack w="100%" justify="center" align="center">
//         <Select
//           w={{ sm: "100%", md: "25%" }}
//           bg={colors?.secondary || "white"}
//           color={colors?.third || "dark"}
//         >
//           {options.map((op, idx) => {
//             return (
//               <option key={idx} value={op.id}>
//                 {op.label}
//               </option>
//             );
//           })}
//         </Select>
//         <Input
//           placeholder="Insira o código do voucher"
//           value={voucherCode}
//           onChange={(e) => setVoucherCode(e.target.value)}
//         />
//         <Button
//           onClick={handleValidation}
//           color={colors?.secondary}
//           bg={colors?.primary}
//           _hover={{}}
//           isLoading={isLoading}
//         >
//           Buscar voucher
//         </Button>
//       </HStack>
//     </Container>
//   );
// }
