import rootId from '../data/rootId';
import { IBodyRegister } from '../types/requestRegister';
import { api } from './api';

const superToken =
	'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzU0MWRjYjEwNTQzZGFiOGYyMmVmNmYiLCJpc0FkbWluIjpmYWxzZSwiaXNTdXBlciI6ZmFsc2UsImlzQ3VzdG9tZXIiOnRydWUsInJvb3RJZCI6ImYzNmI2ZmY1LTViZGYtNTQ0OC1iMTVjLWU2NTM0MDdjYjYwMCIsImN1c3RvbWVyIjp7ImFkZHJlc3MiOnsiYWRkcmVzc0xpbmUxIjoiUnVhIGRvIFRlc3RlIn0sIl9pZCI6IjYzNTQxZGNiMTA1NDNkYWI4ZjIyZWY2ZiIsIm5hbWUiOiJBbGFuIFNpbHZhIiwiZW1haWwiOiJlbWFpbDE0QHRvb3dlemUuY29tLmJyIiwibW9iaWwiOm51bGwsImdlbmRlciI6IiIsImV4dGVybmFsSUQiOiIiLCJiaXJ0aERheSI6bnVsbCwicGVyc29uYWxJZCI6IiIsInBlcnNvbmFsc2Vjb25kSWQiOiIiLCJzdGF0dXMiOiJBY3RpdmUiLCJwcm9maWxlUGljIjoiIiwib3JpZ2luIjoiIiwicm9vdElkIjoiZjM2YjZmZjUtNWJkZi01NDQ4LWIxNWMtZTY1MzQwN2NiNjAwIiwiY3JlYXRlZEJ5IjoiNWNlMzI2ZmE1OWEzNTQwMDE3NDc0NjVjIiwiY3JlYXRlZEF0IjoxNjY2NDU3MDI2LCJfX3YiOjB9LCJpYXQiOjE2NjY2MTI2MzB9.M2B3TnB7ITWOdzAwnaERTE-haD8GXkZtJ40qciDBUMY';

export const login = async ({ email, password }: { email: string; password: string }): Promise<any> => {
	const body = { email, password, rootId };

	return await api
		.post('customer/auth', body)
		.then((res) => res)
		.catch((err) => err.response);
};

export const register = async (body: IBodyRegister) => {
	return await api
		.post('customer', body, {
			headers: {
				'x-auth-token': superToken,
			},
		})
		.then((res) => res)
		.catch((e) => e.response);
};

// AQUI
export const sendEmailToRecoveryPassword = async (email: string) => {
	return await api
		.post('customer/auth/passwordrecovery', { email, rootId })
		.then((res) => res)
		.catch((e) => e.response);
};

export const recoveryPassword = async ({ password, token }: { password: string; token: string }) => {
	return await api
		.post('customer/auth/changepassword/' + token, { password })
		.then((res) => res)
		.catch((e) => e.response);
};

export const editCustomer = async (body: any, token: string) => {
	return await api
		.patch('/customer', body, { headers: { 'x-auth-token': token } })
		.then((res) => res)
		.catch((e) => e.response);
};

export const getExtractCashback = async (token: string) => {
	return await api('customerbalance/cashback', {
		headers: {
			'x-auth-token': token,
		},
	})
		.then((res) => res)
		.catch((e) => e.response);
};

export const getExtractLoyalty = async (token: string) => {
	return await api('customerbalance/loyalty', {
		headers: {
			'x-auth-token': token,
		},
	})
		.then((res) => res)
		.catch((e) => e.response);
};

export const getBalanceCashback = async (token: string) => {
	return await api('customertotalbalance/cashback', {
		headers: {
			'x-auth-token': token,
		},
	})
		.then((res) => res)
		.catch((e) => e.response);
};

export const getBalanceLoyalty = async (token: string) => {
	return await api('customertotalbalance/loyalty', {
		headers: {
			'x-auth-token': token,
		},
	})
		.then((res) => res)
		.catch((e) => e.response);
};

export const rewardVoucher = async (token: string, body: { productId: string }) => {
	return await api
		.post('rewardvoucher', body, {
			headers: {
				'x-auth-token': token,
			},
		})
		.then((res) => [res, null])
		.catch((e) => [null, e?.response || e]);
};

export const getVouchersService = async (token: string) => {
	return await api
		.get('rewardvoucher', {
			headers: {
				'x-auth-token': token,
			},
		})
		.then((res) => [res, null])
		.catch((e) => [null, e?.response || e]);
};
