import axios from "axios";
import { ICity } from "../types/cities";

export const getAdressByCep = async (cep: string) => {
    const url = `https://viacep.com.br/ws/${cep}/json/`;

    return await axios
        .get(url)
        .then((response) => response)
        .catch((e) => e);
};

export const getCitiesByStateId = async (stateId: string) => {
    const url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/municipios`
    return await axios(url)
        .then(res => res)
        .catch(e => e?.response)
}

export const getCities = async (stateId: string): Promise<ICity[] | null>  => {
    const response = await getCitiesByStateId(stateId);
    if(response && response.status === 200){
        const cities : ICity[] = await response.data;
        return cities;
    }

    return null;
}